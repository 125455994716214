import * as React from "react"
import Svg, { Path } from "react-native-svg"

const Minimize = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <Path data-name="Rectangle 391" fill="none" d="M0 0h24v24H0z" />
    <Path data-name="Path 10198" d="M5 11.4h14v2H5Z" fill="#919191" />
  </Svg>
)

export default Minimize