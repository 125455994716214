import React from "react";

import { Modal, Button } from "native-base";
import ShadowButton from "./ShadowButton";

const ShadowModal = (props) => {
  return (
    <Modal
      mb={5}
      size={props.size || 'xl'}
      isOpen={props.showModal}
      _android={{}}
      // _backdrop={{
      //   bg:"red.500"
      // }}
      onClose={() => props.setShowModal(false)}
    >
      <Modal.Content
        style={{
          backgroundColor: "white",
          borderRadius: 20,
          // marginTop: Platform.OS === "android" ? -50 : -200,
        }}
      >
        <Modal.CloseButton />
        <Modal.Header
          borderBottomWidth={0}
          backgroundColor='white'
          _text={{ textAlign: "center", fontSize: 16 }}
        >
          {props.title}
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        {/* <Modal.Footer backgroundColor={"white"}>
          <Button.Group space={2}>
            <ShadowButton onPress={props.onReject} text='Reject' />
            <ShadowButton
              primary
              isLoading={props.isAccepting}
              onPress={props.onAccept}
              text='Accept'
            />
          </Button.Group>
        </Modal.Footer> */}
      </Modal.Content>
    </Modal>
  );
};

export default ShadowModal;
