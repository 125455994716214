import React, { useRef, useState, useEffect } from "react";
// import { StatusBar } from "react-native";
import DropdownAlert from "react-native-dropdownalert";
import { AlertHelper } from "./AlertHelper";

const DropdownNotification = ({ type, title, msg }) => {
  const dda = useRef();

  useEffect(() => {
    let unmounted = true;

    if (!unmounted) {
      dda.current.alertWithType(type, title, msg);
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <DropdownAlert
      updateStatusBar={false}
      defaultContainer={{
        padding: 8,
        paddingTop: 0,
        flexDirection: "row",
      }}
      ref={(ref) => AlertHelper.setDropDown(ref)}
      onClose={() => AlertHelper.invokeOnClose()}
      zIndex={99}
    />
  );
};

export default DropdownNotification;
