import React, { useState, useEffect } from "react";
import { NativeBaseProvider } from "native-base";
import { useAsyncStorage } from "@react-native-async-storage/async-storage";
import * as Font from "expo-font";
import {
  Ionicons,
  MaterialIcons,
  FontAwesome,
} from "@expo/vector-icons";
import Support from "./screens/private/Support";
import Login from "./screens/public/Login";
import DropdownNotification from "./DropdownNotification";

const fetchFonts = () => {
  return Font.loadAsync({
    // Roboto: require("./src/fonts/Roboto.ttf"),
    // Roboto_medium: require("./src/fonts/Roboto_medium.ttf"),
    Poppins_medium: require("./fonts/Poppins/Poppins-Medium.ttf"),
    Poppins_medium: require("./fonts/Poppins/Poppins-Medium.ttf"),
    ...Ionicons.font,
    ...FontAwesome.font,
    // ...MaterialCommunityIcons.font,
    ...MaterialIcons.font,
    // LiuJianMaoCao: require("./src/screens/templates/assets/fonts/LiuJianMaoCao-Regular.ttf"),
    // Quicksand: require("./src/screens/templates/assets/fonts/Quicksand-Regular.ttf"),
    // Lobster: require("./src/screens/templates/assets/fonts/Lobster-Regular.ttf"),
    // Satisfy: require("./src/screens/templates/assets/fonts/Satisfy-Regular.ttf"),
    // Pacifico: require("./src/screens/templates/assets/fonts/Pacifico-Regular.ttf"),
    // AmaticSC: require("./src/screens/templates/assets/fonts/AmaticSC-Regular.ttf"),
    // Caveat: require("./src/screens/templates/assets/fonts/Caveat-Regular.ttf"),
    // Courgette: require("./src/screens/templates/assets/fonts/Courgette-Regular.ttf"),
    // DancingScript: require("./src/screens/templates/assets/fonts/DancingScript-Regular.ttf"),
    // IndieFlower: require("./src/screens/templates/assets/fonts/IndieFlower-Regular.ttf"),
    // ShadowsIntoLight: require("./src/screens/templates/assets/fonts/ShadowsIntoLight-Regular.ttf"),
    // Cabin: require("./src/screens/templates/assets/fonts/Cabin-Medium.ttf"),
  });
};

function App() {
  const { getItem } = useAsyncStorage("@supportUser");
  const [appIsReady, setAppIsReady] = useState(false);
  const [isIn, setIsIn] = useState(false);
  const [user, setUser] = useState(null);
  // console.log("Support User Logged In:", isIn, user);

  useEffect(() => {
    async function prepare() {
      try {
        await fetchFonts();
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }
    prepare();
  }, []);

  useEffect(() => {
    getItem((err, result) => {
      if (result) {
        setIsIn(true);
        setUser(JSON.parse(result));
      }
    });
  }, [isIn]);

  return (
    <NativeBaseProvider>
      {user ? (
        <Support setIsIn={setIsIn} setUser={setUser} user={user} />
      ) : (
        <Login setIsIn={setIsIn} />
      )}
      <DropdownNotification />
    </NativeBaseProvider>
  );
}

export default App;
