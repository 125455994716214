import * as React from "react"
import Svg, { Path } from "react-native-svg"

const Close = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <Path data-name="Rectangle 391" fill="none" d="M0 0h24v24H0z" />
    <Path
      data-name="Path 10197"
      d="m12 10.984 4.944-4.944 1.416 1.4-4.944 4.96 4.944 4.944-1.416 1.416L12 13.816 7.056 18.76 5.64 17.344l4.944-4.944L5.64 7.444l1.416-1.4Z"
      fill="#919191"
    />
  </Svg>
)

export default Close
