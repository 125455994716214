import React, { useEffect, useState } from "react";

import { Button } from "native-base";
import Colors from "../constants/Colors";

const ShadowButton = (props) => {
  const [wait, setWait] = useState(props.wait || 0);
  const [pressed, setPressed] = useState(false);
  const [text] = useState(props.text);
  const bg = props.primary ? Colors.primary : "#525252";
  const color = props.primary ? Colors.primary : "#525252";
  useEffect(() => {
    let i = null;
    if (wait !== 0) {
      i = setInterval(() => {
        setWait((w) => {
          if (w === 0) {
            clearInterval(i);
            return 0;
          }
          return w - 1;
        });
      }, 1000);
    }
    return () => {
      if (i) {
        clearInterval(i);
      }
    };
  }, []);
  return (
    <Button
      isDisabled={props.isDisabled ? props.isDisabled : false}
      isLoading={props.isLoading}
      onPressIn={() => {
        setPressed(true);
      }}
      onPressOut={() => {
        setPressed(false);
      }}
      // variant={pressed ? "outline" : "solid"}
      borderWidth={pressed || props.isLoading ? 1 : 1}
      borderColor={pressed || props.isLoading ? color : color} //changed
      onPress={() => {
        if (!wait) {
          props.onPress();
        }
      }}
      _text={{
        color:
          pressed || props.isLoading
            ? color
            : props.isDisabled
            ? "warmGray.800"
            : "white",
        fontSize: props.fontSize || 16,
      }}
      _pressed={{
        bg: "white",
      }}
      _loading={{
        bg: props.isLoading ? "white" : color,
      }}
      bg={pressed || props.isLoading ? "white" : bg}
      rounded={50}
      style={{ height: 48, ...props.style }}
    >
      {props.isLoading ? "" : wait ? wait + "s" : text}
    </Button>
  );
};

export default ShadowButton;
