import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const WhiteLogo = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.995}
    height={34.056}
    {...props}
  >
    <G fill="#7d2be9">
      <G data-name="Group 1">
        <Path
          data-name="Path 1"
          d="M5.817 11.034c0-2.651 3.059-4.862 14.116-8.583a12.41 12.41 0 0 0-7.428-2.45A12.5 12.5 0 0 0 0 12.501a18.629 18.629 0 0 0 2.312 8.09 94.09 94.09 0 0 0 4.022-1.04v-2.23l1.757-1.448 1.757 1.448v1.033c2.065-.84 3.336-1.726 3.313-2.635-.047-2.12-7.344-2.197-7.344-4.685Zm4.723 6.095-.193.223a.1.1 0 0 1-.062.031h-.008a.087.087 0 0 1-.062-.023l-2.119-1.757-2.111 1.765a.131.131 0 0 1-.077.023.1.1 0 0 1-.062-.031l-.193-.223a.125.125 0 0 1-.023-.069.08.08 0 0 1 .031-.062l2.2-1.834a.349.349 0 0 1 .231-.077.336.336 0 0 1 .231.077l.747.624v-.593a.075.075 0 0 1 .031-.069.1.1 0 0 1 .069-.031h.586a.075.075 0 0 1 .069.031.1.1 0 0 1 .031.069v1.248l.67.555a.07.07 0 0 1 .031.062.068.068 0 0 1-.016.06Z"
        />
        <Path
          data-name="Path 2"
          d="M8.483 17.83v1.04c-.254.085-.516.177-.786.262v-1.3Z"
        />
        <Path
          data-name="Path 3"
          d="M24.995 12.497c0 9.762-12.5 21.559-12.5 21.559a43.757 43.757 0 0 1-4.977-5.509l-3.914 1.441A139.54 139.54 0 0 0 7.171 28.1c11.512-6.287 14.015-9.955 13.569-12.236-.416-2.1-2.18-3.2-4.115-3.968v-2.92h-.639v1.942h.193v.809c-.1-.039-.2-.077-.308-.108V8.737h-1.279v2.458c-.1-.031-.2-.062-.308-.1v-.169h.193V8.984h-.639v1.969c-1.6-.524-2.882-1.04-3.051-1.98-.393-2.157 4.1-4.561 9.2-6.472a12.454 12.454 0 0 1 5.008 9.996Z"
        />
        <Path
          data-name="Path 4"
          d="M15.38 10.864v.586c-.1-.031-.2-.069-.293-.1v-.486Z"
        />
        <Path data-name="Rectangle 2" d="M15.326 9.061h.293v.362h-.293z" />
        <Path data-name="Rectangle 3" d="M14.848 9.061h.293v.362h-.293z" />
        <Path data-name="Rectangle 4" d="M14.848 9.639h.293v.362h-.293z" />
        <Path data-name="Rectangle 5" d="M15.326 9.639h.293v.362h-.293z" />
        <Path data-name="Rectangle 6" d="M15.326 10.217h.293v.362h-.293z" />
        <Path data-name="Rectangle 7" d="M14.848 10.217h.293v.362h-.293z" />
        <Path data-name="Rectangle 8" d="M16.127 10.332h.27v.331h-.27z" />
        <Path data-name="Rectangle 9" d="M16.127 9.801h.27v.331h-.27z" />
        <Path data-name="Rectangle 10" d="M16.127 9.277h.27v.331h-.27z" />
        <Path data-name="Rectangle 11" d="M14.07 10.332h.27v.331h-.27z" />
        <Path data-name="Rectangle 12" d="M14.07 9.801h.27v.331h-.27z" />
        <Path data-name="Rectangle 13" d="M14.07 9.277h.27v.331h-.27z" />
      </G>
      <G data-name="Group 2">
        <Path
          data-name="Path 5"
          d="M13.484 17.491a1.207 1.207 0 0 1-.462.963c-2.35 1.98-9.678 3.645-12.583 3.575a60.3 60.3 0 0 0 6.056-1.664c.4-.131.8-.27 1.21-.408l.786-.277c.462-.17.925-.347 1.372-.524a35.164 35.164 0 0 0 3.621-1.665Z"
        />
        <Path
          data-name="Path 6"
          d="M11.003 6.873a9.068 9.068 0 0 0-3.506 2.481 6.43 6.43 0 0 0-.9 1.765 1.9 1.9 0 0 1 .208-1.533c.607-1.113 2.588-2.158 4.198-2.713Z"
        />
        <Path
          data-name="Path 7"
          d="M20.172 16.782c-.585-2.319-2.812-2.974-4.261-3.56-3.244-1.31-3.984-1.533-5.448-2.674a1.407 1.407 0 0 1-.462-1.225c.7 1.179 2.519 1.88 3.845 2.365.154.054.308.108.447.162.108.039.208.077.308.116.123.046.231.092.323.131a1.637 1.637 0 0 1 .17.077c.1.046.2.085.293.131.138.062.277.123.408.177s.262.108.385.162c.154.062.3.131.447.193 2.358 1 3.336 1.5 3.529 3.56a3.163 3.163 0 0 1 .016.385Z"
        />
      </G>
    </G>
  </Svg>
)

export default WhiteLogo
