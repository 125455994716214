import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  HStack,
  Input,
  Text,
  FlatList,
  Button,
  Center,
  VStack,
  Pressable,
  Avatar,
  Spacer,
  Actionsheet,
  useToast,
  useDisclose,
  FormControl,
  Menu,
  Divider,
  Badge,
  Stack,
} from "native-base";
import { Ionicons, Entypo } from "@expo/vector-icons";
import moment from "moment-timezone";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
// import { GiftedChat, Bubble, Composer } from "react-native-gifted-chat";
import { useAsyncStorage } from "@react-native-async-storage/async-storage";
import { AlertHelper } from "../../AlertHelper";
import ShadowModal from "../../components/ShadowModal";
import WhiteLogo from "../../assets/js/Logo";
import Send from "../../assets/js/Send";
import Minimize from "../../assets/js/Minimize";
import Close from "../../assets/js/Close";
import ShadowButton from "../../components/ShadowButton";
const API_URL = "https://chat.shadow.properties";

const ManageUsers = () => {
  const [users, setUsers] = useState();
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const fetchUsers = async () => {
      const body = JSON.stringify({
        skip: page === 1 ? 0 : (page - 1) * limit,
        limit,
        sort: {
          _id: -1,
        },
        filter: {},
      });
      try {
        const response = await fetch(`${API_URL}/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + token,
          },
          body,
        });

        if (!response.ok) {
          const errorRes = await response.json();
          let message = "Something went wrong!";
          if (errorRes && errorRes.error) {
            message = errorRes.error;
          }
          throw new Error(message);
        }

        const resData = await response.json();
        console.log(resData.data);
        setUsers(resData.data ? resData.data : []);
      } catch (err) {
        // send to custom analytics server
        console.log("Error ", err);
        throw err;
      }
    };
    if (!unmounted) {
      fetchUsers();
    }
    return () => {
      unmounted = true;
    };
  }, [page]);

  const changeStatus = async (agent, active) => {
    try {
      const response = await fetch(`${API_URL}/users`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ agent, active }),
      });
      const resData = await response.json();
      if (resData.data && resData.data.success) {
        setUsers((u) => {
          return u.map((m) => {
            console.log(m._id, "===", agent);
            if (m._id === agent) {
              m.active = active;
            }
            return m;
          });
        });
        setRefresh(!refresh);
      }
    } catch (err) {
      console.log(err);
      // throw err.message;
    }
  };

  const changeRole = async (agent, role) => {
    try {
      const response = await fetch(`${API_URL}/users`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ agent, role }),
      });
      const resData = await response.json();
      if (resData.data && resData.data.success) {
        setUsers((u) => {
          return u.map((m) => {
            if (m._id === agent) {
              m.role = role;
            }
            return m;
          });
        });
        setRefresh(!refresh);
      }
    } catch (err) {
      console.log(err);
      // throw err.message;
    }
  };

  return (
    <FlatList
      // ListHeaderComponent={<Heading>Users</Heading>}
      // onContentSizeChange={() => {
      //   msgList.current.scrollToEnd();
      // }}
      data={users}
      keyExtractor={(item) => (item._id ? item._id.toString() : "")}
      renderItem={({ item }) => {
        return (
          <HStack space={3} justifyContent='space-between'>
            <Center>
              <Avatar
                size='32px'
                source={{
                  uri: item.avatar,
                }}
              />
            </Center>
            <VStack>
              <Text
                fontSize='xs'
                _dark={{
                  color: "warmGray.50",
                }}
                color='coolGray.800'
                bold
              >
                {item.firstname} {item.lastname}
              </Text>
              <Text
                fontSize='xs'
                _dark={{
                  color: "warmGray.50",
                }}
                color='coolGray.800'
              >
                {item.email}
              </Text>
            </VStack>
            <Spacer />
            <Pressable
              onPress={() => {
                changeRole(item._id, item.role === "admin" ? "user" : "admin");
              }}
            >
              <Text style={{ textTransform: "uppercase" }}>{item.role}</Text>
            </Pressable>
            <Spacer />
            <VStack>
              <Pressable
                onPress={async () => {
                  await changeStatus(item._id, !item.active);
                }}
              >
                <Badge
                  variant={"solid"}
                  colorScheme={item.active ? "success" : "default"}
                >
                  {item.active ? "Active" : "In-Active"}
                </Badge>
              </Pressable>
              <Text
                fontSize={10}
                _dark={{
                  color: "warmGray.50",
                }}
                color='coolGray.800'
                alignSelf='flex-start'
              >
                {moment(item.createdAt).format("DD-MMM-YYYY HH:mm")}
              </Text>
            </VStack>
          </HStack>
        );
      }}
    />
  );
};

const PreviousChats = ({ customer }) => {
  const [chats, setChats] = useState();
  const [page, setPage] = useState(1);
  const [limit] = useState(50);
  const [refresh, setRefresh] = useState(false);

  // console.log(customer, chats);

  useEffect(() => {
    let unmounted = false;
    const fetchChats = async () => {
      const body = JSON.stringify({
        skip: page === 1 ? 0 : (page - 1) * limit,
        limit,
        sort: {
          ts: -1,
        },
        filter: { customer },
      });
      try {
        const response = await fetch(`${API_URL}/chats`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + token,
          },
          body,
        });

        if (!response.ok) {
          const errorRes = await response.json();
          let message = "Something went wrong!";
          if (errorRes && errorRes.error) {
            message = errorRes.error;
          }
          throw new Error(message);
        }

        const resData = await response.json();
        // console.log(resData.data);
        setChats(resData.data ? resData.data : []);
      } catch (err) {
        // send to custom analytics server
        console.log("Error ", err);
        throw err;
      }
    };
    if (!unmounted) {
      fetchChats();
    }
    return () => {
      unmounted = true;
    };
  }, [page]);

  return (
    <FlatList
      // ListHeaderComponent={<Heading>Users</Heading>}
      // onContentSizeChange={() => {
      //   msgList.current.scrollToEnd();
      // }}
      contentContainerStyle={{
        height: 200,
      }}
      data={chats}
      keyExtractor={(item) => (item._id ? item._id.toString() : "")}
      renderItem={({ item }) => {
        return (
          <HStack space={3} justifyContent='flex-start'>
            <Text fontSize={12}>
              {moment(item.ts).format("MM-DD-YYYY HH:mm")}
            </Text>
            <Text fontSize={12} style={{ textAlign: "left" }}>
              {item.text}
            </Text>
          </HStack>
        );
      }}
    />
  );
};

const CreateUser = ({ setCreateUser, setShowPreviousChats }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const saveUser = async (data) => {
    const body = JSON.stringify(data);
    try {
      const response = await fetch(`${API_URL}/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
        },
        body,
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      const resData = await response.json();
      console.log(resData.data);
      if (resData.data) {
        AlertHelper.show(
          "success",
          "Success",
          `User ${data.firstname} created successfully.`
        );
        setCreateUser(false);
        setShowPreviousChats(false);
      }
    } catch (err) {
      // send to custom analytics server
      console.log("Error ", err);
      throw err;
    }
  };

  return (
    <Box>
      <FormControl>
        <Stack space={2}>
          <Stack>
            <FormControl.Label>Firstname</FormControl.Label>
            <Input
              value={firstname}
              onChangeText={(v) => {
                setFirstname(v);
              }}
              variant='underlined'
              p={2}
              placeholder='Firstname'
            />
          </Stack>
          <Stack>
            <FormControl.Label>Lastname</FormControl.Label>
            <Input
              value={lastname}
              onChangeText={(v) => {
                setLastname(v);
              }}
              variant='underlined'
              p={2}
              placeholder='Lastname'
            />
          </Stack>
          <Stack>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              value={email}
              onChangeText={(v) => {
                setEmail(v);
              }}
              variant='underlined'
              p={2}
              placeholder='Email'
            />
          </Stack>
          <Stack>
            <FormControl.Label>Password</FormControl.Label>
            <Input
              value={password}
              onChangeText={(v) => {
                setPassword(v);
              }}
              variant='underlined'
              p={2}
              placeholder='Password'
            />
          </Stack>
          <Stack>
            <FormControl.Label>Confirm Password</FormControl.Label>
            <Input
              value={confirmpassword}
              onChangeText={(v) => {
                setConfirmPassword(v);
              }}
              variant='underlined'
              p={2}
              placeholder='Confirm Password'
            />
          </Stack>
        </Stack>
      </FormControl>
      <Button.Group space={2} mt={2}>
        <ShadowButton
          onPress={() => {
            setShowPreviousChats(false);
            setCreateUser(false);
          }}
          text='Cancel'
        />
        <ShadowButton
          primary
          style={{ width: 80 }}
          isLoading={isLoading}
          onPress={() => {
            if (firstname === "") {
              AlertHelper.show(
                "error",
                "Validation Failed",
                "Firstname is required"
              );
              return;
            }
            if (email === "") {
              AlertHelper.show(
                "error",
                "Validation Failed",
                "Email is required"
              );
              return;
            }
            if (password === "") {
              AlertHelper.show(
                "error",
                "Validation Failed",
                "Password is required"
              );
              return;
            }

            if (password !== confirmpassword) {
              AlertHelper.show(
                "error",
                "Validation Failed",
                "Password do not match"
              );
              return;
            }

            saveUser({ firstname, lastname, email, password, role: "user" });
            // console.log(firstname, lastname, email, password);
          }}
          text='Save'
        />
      </Button.Group>
    </Box>
  );
};

function Support({ user, setIsIn, setUser }) {
  const iRef = useRef([]);
  const sRef = useRef([]);
  const cRef = useRef([]);
  const fRef = useRef();
  // const { isOpen, onOpen, onClose } = useDisclose();
  const { removeItem } = useAsyncStorage("@supportUser");
  const toast = useToast();
  const [socket, setSocket] = useState(null);
  // const customersList = useRef();
  // const [connectedTo, setConnectedTo] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [agents, setAgents] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [createUser, setCreateUser] = useState(false);
  const [queue, setQueue] = useState([]);
  const [active, setActive] = useState([]);
  const [closedSessions, setClosedSessions] = useState([]);
  const [pm, setPm] = useState(null);
  const [showPreviousChats, setShowPreviousChats] = useState(true);
  const [pcCustomer, setPcCustomer] = useState(null);
  // const [acceptCustomer, setAcceptCustomer] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoadingEarlier, setIsLoadingEarlier] = useState(false);
  // const [page, setPage] = useState(0);
  // const [sent, setSent] = useState(false);
  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [sent, setSent] = useState(0);
  // const [messages, setMessages] = useState([]);

  useEffect(() => {
    let unmounted = false;
    const fetchTemplates = async () => {
      // const body = JSON.stringify({
      //   skip: 0,
      //   limit: 100,
      //   filter: { agent: user._id },
      // });
      try {
        const response = await fetch(`${API_URL}/templates/${user._id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + token,
          },
          // body,
        });

        if (!response.ok) {
          const errorRes = await response.json();
          let message = "Something went wrong!";
          if (errorRes && errorRes.error) {
            message = errorRes.error;
          }
          throw new Error(message);
        }

        const resData = await response.json();
        // console.log(resData.data);
        setTemplates(resData.data ? resData.data : []);
      } catch (err) {
        // send to custom analytics server
        console.log("Error ", err);
        throw err;
      }
    };
    if (!unmounted) {
      fetchTemplates();
    }
    return () => {
      unmounted = true;
    };
  }, [reload]);

  useEffect(() => {
    const connectSocket = async () => {
      try {
        // let url = `ws://localhost:9001`;
        let url = `https://chat.shadow.properties`;
        const socket = io(url, {
          reconnection: true,
          transports: ["websocket"],
          allowUpgrades: false,
          pingTimeout: 30000,
          secure: true,
        });

        socket
          .on("connect", () => {
            socket.emit("agents:join", {
              _id: user._id,
              email: user.email,
              name: `${user.firstname} ${user.lastname}`,
            });
            setMessages([]);
            if (global.error) {
              AlertHelper.show("success", "Connected", "server is back");
              delete global.error;
            }
            console.log("Connected to shadow support server", socket.id);
          })
          .on("disconnect", async (reason) => {
            console.log(reason);
            if (reason !== "io client disconnect") {
              AlertHelper.show(
                "error",
                "Disconnected",
                "disconnected from shadow support server"
              );
            }
          })
          .on("connect_error", (error) => {
            if (!global.error) {
              AlertHelper.show("error", "Connect Error", "network error");
              global.error = true;
              console.log("socket server error", error.message);
            }
          });

        setSocket(socket);
      } catch (err) {
        console.log("Connect socket error", err);
        AlertHelper.show("error", "Error", "something went wrong");
        return null;
      }
    };

    connectSocket();

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let si = null;
    if (socket) {
      socket
        .on("customer:left:chat", (cid) => {
          if (sRef.current[cid]) {
            sRef.current[cid].setNativeProps({ style: { color: "orange" } });
          }
        })
        .on("customer:on:chat", (cid) => {
          if (sRef.current[cid]) {
            sRef.current[cid].setNativeProps({ style: { color: "darkgreen" } });
          }
        })
        .on("sessions", (data) => {
          // console.log(data, "sessions")
          setClosedSessions(data);
          setRefresh(moment().valueOf());
        })
        .on("customers:list", (data) => {
          setQueue(data.filter((c) => !c.chat));
          setActive(data.filter((c) => c.chat && c.agent === user._id));
          setRefresh(moment().valueOf());
        })
        .on("open:chat", (data) => {
          setPm((p) => {
            if (!p) {
              p = {};
            }
            if (!p[data.room]) {
              p[data.room] = {};
            }
            p[data.room] = data;
            // console.log(p[data.room], "inside pm");
            return p;
          });
          setRefresh(moment().valueOf());
        })
        .on("pm", (data) => {
          // console.log("PM REC", data)
          setPm((p) => {
            const room = data.system ? data.room : data.user.room;
            if (p[room]) {
              p[room].messages = [data, ...p[room].messages];
            }
            return p;
          });
          setRefresh(moment().valueOf());
        })
        .on("messages", (data) => {
          // console.log(data);
          setIsLoadingEarlier(false);
          setPm((p) => {
            const room = data[0].room;
            p[room].messages = [...p[room].messages, ...data];
            return p;
          });
          setRefresh(moment().valueOf());
          // if (msg.data) {
          //   setMessages((m) => [...m, ...msg.data]);
          // }
          // setSent(!sent);
        });
    }

    return () => {
      if (si) {
        clearInterval(si);
      }
      if (socket) {
        socket.off("agents:list");
        socket.off("connect:customer");
        socket.off("customers");
        socket.off("chat");
        socket.off("messages");
        setAgents([]);
        setMessages([]);
        setCustomers(0);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const logout = () => {
    if (user) {
      removeItem((err) => {
        if (!err) {
          setIsIn(false);
          setUser(null);
          if (socket) {
            socket.disconnect();
          }
          toast.show({
            title: "Signout successfull!",
            status: "success",
            duration: 3000,
            placement: "top",
          });
        }
      });
    }
  };

  const send = (text, customer) => {
    socket.emit("pm", {
      _id: uuidv4(),
      text,
      createdAt: moment().toISOString(),
      customer,
      user: {
        _id: user._id,
        name: user.firstname + " " + user.lastname,
        email: user.email,
        agent: true,
        room: customer.email,
      },
    });
    if (cRef.current) {
      cRef.current[customer.email].value = "";
    }
  };

  const saveTemplate = async (data) => {
    const body = JSON.stringify(data);
    try {
      const response = await fetch(`${API_URL}/templates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
        },
        body,
      });

      if (!response.ok) {
        const errorRes = await response.json();
        let message = "Something went wrong!";
        if (errorRes && errorRes.error) {
          message = errorRes.error;
        }
        throw new Error(message);
      }

      const resData = await response.json();
      // console.log(resData.data);
      if (resData.data) {
        AlertHelper.show(
          "success",
          "Success",
          `Template ${data.name} created successfully.`
        );
        setReload(!reload);
      }
    } catch (err) {
      // send to custom analytics server
      console.log("Error ", err);
      throw err;
    }
  };

  const _keyExtractor = useCallback((item) => item._id, []);

  return (
    <Box h={"100vh"} flex={1}>
      {showModal && (
        <ShadowModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={
            showPreviousChats
              ? "Previous Chats"
              : createUser
              ? "Create User"
              : "Manage Users"
          }
        >
          {showPreviousChats ? (
            <PreviousChats customer={pcCustomer} />
          ) : createUser ? (
            <CreateUser
              setCreateUser={setCreateUser}
              setShowPreviousChats={setShowPreviousChats}
            />
          ) : (
            <Box>
              <Button
                rounded={"full"}
                my={2}
                style={{ width: 100 }}
                variant={"outline"}
                size='sm'
                onPress={() => {
                  setCreateUser(true);
                  setShowPreviousChats(false);
                }}
              >
                Create User
              </Button>
              <ManageUsers />
            </Box>
          )}
        </ShadowModal>
      )}

      {/* App Bar */}
      <HStack
        justifyContent={"space-between"}
        height={70}
        style={{
          shadowColor: "#000000",
          shadowOffset: { width: 0, height: 5 },
          shadowOpacity: 0.1,
          shadowRadius: 15,
          zIndex: 999,
        }}
      >
        <HStack>
          <Center style={{ paddingLeft: 20 }}>
            <WhiteLogo />
          </Center>
          <Center style={{ height: 38, marginTop: 15, marginLeft: 10 }}>
            <Text
              style={{
                color: "#7D2BE9",
                fontSize: 14,
                fontWeight: "600",
              }}
            >
              Chat{"\n"}Support
            </Text>
          </Center>
        </HStack>
        <Center>
          <HStack mx={4} space={2}>
            <Center>
              <Avatar size={36} />
            </Center>
            <VStack>
              <Text
                color='#343434'
                fontWeight={600}
                fontSize={14}
              >{`${user.firstname} ${user.lastname}`}</Text>
              <Text
                textTransform={"capitalize"}
                fontWeight={"medium"}
                fontSize={12}
                color={"#100DB1"}
              >
                {user.role}
              </Text>
            </VStack>
            <Center ml={2}>
              <Menu
                w='190'
                mr={2}
                _text={{ fontSize: 12 }}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      accessibilityLabel='More options menu'
                      {...triggerProps}
                    >
                      <Ionicons size={16} name='chevron-down' color={"#ccc"} />
                    </Pressable>
                  );
                }}
              >
                {user.role === "admin" && (
                  <Menu.Item
                    onPress={() => {
                      setShowModal(!showModal);
                      setPcCustomer(null);
                      setShowPreviousChats(false);
                    }}
                  >
                    Manage Users
                  </Menu.Item>
                )}
                <Menu.Item
                  onPress={async () => {
                    let name = prompt("Enter template", "");

                    if (name !== null) {
                      await saveTemplate({ name, agent: user._id });
                    }
                  }}
                >
                  Manage Templates
                </Menu.Item>
                <Menu.Item onPress={logout}>Logout</Menu.Item>
              </Menu>
            </Center>
          </HStack>
        </Center>
      </HStack>

      <HStack flex={1} h={"92vh"}>
        {/* Left Block */}
        <VStack
          width={366}
          pt={1}
          bg='white'
          style={{
            shadowColor: "#000000",
            shadowOffset: { width: 10, height: 0 },
            shadowOpacity: 0.1,
            shadowRadius: 15,
            zIndex: 998,
          }}
        >
          {/* queue */}
          {queue && queue.length > 0 && (
            <FlatList
              ListHeaderComponent={
                <Box
                  bg='white'
                  p={2}
                  fontSize={14}
                  _text={{ textAlign: "center", color: "white" }}
                >
                  {/* {"Customers (" + customers.length + ")"} */}
                  <Input />
                </Box>
              }
              contentContainerStyle={{
                height: 200,
              }}
              onContentSizeChange={() => {
                //   msgList.current.scrollToEnd();
              }}
              data={queue}
              keyExtractor={(item) => (item._id ? item._id.toString() : "")}
              renderItem={({ item }) => {
                // console.log("queue item", item);
                return (
                  <Pressable
                    onPress={() => {
                      if (item.chat) {
                        return;
                      }
                      socket.emit("pm", {
                        agent: user._id,
                        customer: {
                          id: item._id,
                          name: item.name,
                          email: item.email,
                        },
                        user: {
                          _id: user._id,
                          name: user.firstname + " " + user.lastname,
                          email: user.email,
                          agent: true,
                          room: item.room,
                        },
                      });
                    }}
                    borderBottomWidth='1'
                    _dark={{
                      borderColor: "gray.600",
                    }}
                    borderColor='coolGray.200'
                    pl='4'
                    pr='5'
                    py='2'
                  >
                    <HStack space={3} justifyContent='space-between'>
                      <Center>
                        <Avatar
                          size='32px'
                          source={{
                            uri: item.avatar,
                          }}
                        />
                      </Center>
                      <VStack>
                        <Text
                          fontSize={15}
                          _dark={{
                            color: "warmGray.50",
                          }}
                          color='#303030'
                          fontFamily={"Poppins_medium"}
                        >
                          {item.name}{" "}
                        </Text>
                        <Text
                          color={"#919191"}
                          fontSize={12}
                          fontFamily={"Poppins_medium"}
                        >
                          {item.email}
                        </Text>
                      </VStack>
                      <Spacer />
                      <Text
                        fontSize={12}
                        fontFamily={"Poppins_medium"}
                        _dark={{
                          color: "warmGray.50",
                        }}
                        color='#C1C1C1'
                        alignSelf='flex-start'
                      >
                        {moment().format("HH:mm")}
                      </Text>
                    </HStack>
                    <HStack
                      style={{ marginTop: 20 }}
                      space={1}
                      justifyContent={"space-between"}
                    >
                      <Text
                        fontSize='12'
                        color='#303030'
                        fontFamily={"Poppins_medium"}
                        style={{ minHeight: 60, width: "92%" }}
                        _dark={{
                          color: "warmGray.200",
                        }}
                      >
                        {item.unreadmsg}
                      </Text>
                      <Badge
                        style={{
                          position: "absolute",
                          left: "92%",
                          top: 15,
                          borderRadius: 5,
                          backgroundColor: "#7D2BE9",
                          color: "white",
                        }}
                        _text={{
                          color: "white",
                        }}
                      >
                        {item.unread}
                      </Badge>
                    </HStack>
                  </Pressable>
                );
              }}
            />
          )}
          {/* active chat */}
          {active && active.length > 0 && (
            <FlatList
              contentContainerStyle={{
                height: 200,
              }}
              data={active}
              keyExtractor={(item) => (item._id ? item._id.toString() : "")}
              renderItem={({ item }) => {
                return (
                  <Pressable
                    onPress={() => {
                      if (item.chat) {
                        return;
                      }
                      socket.emit("pm", {
                        agent: user._id,
                        customer: {
                          id: item._id,
                          name: item.name,
                          email: item.email,
                        },
                        user: {
                          _id: user._id,
                          name: user.firstname + " " + user.lastname,
                          email: user.email,
                          agent: true,
                          room: item.room,
                        },
                      });
                    }}
                    borderBottomWidth='1'
                    _dark={{
                      borderColor: "gray.600",
                    }}
                    borderColor='coolGray.200'
                    pl='4'
                    pr='5'
                    py='2'
                  >
                    <HStack space={3} justifyContent='space-between'>
                      <Center>
                        <Avatar
                          size='32px'
                          source={{
                            uri: item.avatar,
                          }}
                        />
                      </Center>
                      <VStack>
                        <Text
                          fontSize={15}
                          _dark={{
                            color: "warmGray.50",
                          }}
                          color='#303030'
                          fontFamily={"Poppins_medium"}
                        >
                          <Text>
                            {item.name}{" "}
                            {item.chat && (
                              <Ionicons
                                name='chatbubble-ellipses'
                                size={16}
                                color='green'
                              />
                            )}
                          </Text>
                        </Text>
                        <Text
                          color={"#919191"}
                          fontSize={12}
                          fontFamily={"Poppins_medium"}
                        >
                          {item.email}
                        </Text>
                      </VStack>
                      <Spacer />
                      <Text
                        fontSize={12}
                        fontFamily={"Poppins_medium"}
                        _dark={{
                          color: "warmGray.50",
                        }}
                        color='#C1C1C1'
                        alignSelf='flex-start'
                      >
                        {moment().format("HH:mm")}
                      </Text>
                    </HStack>
                  </Pressable>
                );
              }}
            />
          )}
          {/* closed sessions */}
          {closedSessions && closedSessions.length > 0 && (
            <FlatList
              ListHeaderComponent={
                <HStack
                  p={2}
                  fontSize={14}
                  _text={{ textAlign: "center", color: "white" }}
                >
                  <Divider
                    width={"25%"}
                    bg='#919191'
                    opacity={0.15}
                    orientation='horizontal'
                  />
                  <Text
                    width={"50%"}
                    style={{
                      textTransform: "uppercase",
                      fontSize: 13,
                      marginTop: -10,
                      color: "#919191",
                      textAlign: "center",
                    }}
                  >
                    Closed Sessions
                  </Text>
                  <Divider
                    width={"25%"}
                    bg='#919191'
                    opacity={0.15}
                    orientation='horizontal'
                  />
                </HStack>
              }
              contentContainerStyle={{
                height: 200,
              }}
              // ref={qRef}
              onContentSizeChange={() => {
                //   msgList.current.scrollToEnd();
              }}
              data={closedSessions}
              keyExtractor={(item) => (item._id ? item._id.toString() : "")}
              renderItem={({ item }) => {
                // console.log("session item", item);

                return (
                  <Pressable
                    onPress={() => {
                      console.log("closed session pressed");
                      // if (item.chat) {
                      //   return;
                      // }
                      // socket.emit("pm", {
                      //   agent: user._id,
                      //   customer: {
                      //     id: item._id,
                      //     name: item.name,
                      //     email: item.email,
                      //   },
                      //   user: {
                      //     _id: user._id,
                      //     name: user.firstname + " " + user.lastname,
                      //     email: user.email,
                      //     agent: true,
                      //     room: item.room,
                      //   },
                      // });
                    }}
                    // borderBottomWidth='1'
                    _dark={{
                      borderColor: "gray.600",
                    }}
                    // borderColor='#919191'
                    pl='4'
                    pr='5'
                    py='2'
                  >
                    <HStack space={3} justifyContent='space-between'>
                      <Center>
                        <Avatar
                          size='32px'
                          source={{
                            uri: item.avatar,
                          }}
                        />
                      </Center>
                      <VStack>
                        <Text
                          _dark={{
                            color: "warmGray.50",
                          }}
                          color='#303030'
                          fontFamily={"Poppins_medium"}
                          fontSize={15}
                        >
                          {item.name}
                        </Text>
                        <Text>{item.email}</Text>
                      </VStack>
                      <Spacer />
                      <Text
                        fontSize='xs'
                        _dark={{
                          color: "warmGray.50",
                        }}
                        color='coolGray.800'
                        alignSelf='flex-start'
                      >
                        {moment(item.updatedAt).fromNow(true)}
                      </Text>
                    </HStack>
                    <Divider
                      style={{ marginTop: 20 }}
                      // width={"25%"}
                      bg='#919191'
                      opacity={0.15}
                      orientation='horizontal'
                    />
                  </Pressable>
                );
              }}
            />
          )}
        </VStack>
        {/* Right Block */}
        <HStack
          style={{ zIndex: 997 }}
          bg={"#F7F8FA"}
          flex={5}
          flexWrap={"wrap-reverse"}
        >
          {pm &&
            Object.keys(pm).map((p, idx) => {
              const cb = pm[p];
              let showagentnote = cb.showagentnote;
              const customer = cb.customer;
              const cid = customer.id;
              const latestMsg = cb.messages[0];
              const sessid = latestMsg.sessid;
              if (latestMsg.system) {
                showagentnote = latestMsg.system;
              }
              // console.log("SESSID", sessid);
              const sm = cb.messages.filter((m) => m.sessid === sessid);
              const startMsg = sm[sm.length - 1];
              const started = moment(startMsg.ts).fromNow();
              let h = showagentnote ? 620 : 460;
              if (cb.minimized) {
                h = 70;
              }
              return (
                <VStack
                  key={p}
                  direction='column'
                  bg={"white"}
                  // shadow={9}
                  style={{
                    shadowColor: "#000000",
                    shadowOffset: { width: 2, height: 4 },
                    shadowOpacity: 0.1,
                    shadowRadius: 6,
                    marginHorizontal: 20,
                    marginVertical: 10,
                    height: h,
                    width: 320,
                    borderRadius: 10,
                  }}
                >
                  {showagentnote && (
                    <Box>
                      <Text
                        fontSize={13}
                        fontFamily={"Poppins_medium"}
                        color={"#303030"}
                        style={{ paddingLeft: 20, height: 40, lineHeight: 40 }}
                        borderColor={"coolGray.300"}
                        borderBottomWidth={1}
                      >
                        Agent Notes
                      </Text>
                      <Input
                        variant={"unstyled"}
                        height={150}
                        borderRadius={0}
                        multiline
                        ref={(el) => (iRef.current[idx] = el)}
                      />
                    </Box>
                  )}
                  <Pressable
                    onPress={() => {
                      if (cb.minimized) {
                        setPm((c) => {
                          if (c[p].minimized) {
                            c[p].minimized = false;
                          } else {
                            c[p].minimized = true;
                          }
                          return c;
                        });
                        setRefresh(moment().valueOf());
                      }
                    }}
                  >
                    <HStack
                      height={70}
                      space={3}
                      justifyContent='space-between'
                      borderColor={"coolGray.200"}
                      borderBottomWidth={1}
                      // borderTopRadius={10}
                      marginBottom={1}
                      style={{ padding: 20 }}
                    >
                      <Center>
                        <Avatar
                          size='36px'
                          source={{
                            uri: customer.avatar,
                          }}
                        />
                      </Center>
                      <Center>
                        <VStack>
                          <Text
                            fontSize={15}
                            fontFamily='Poppins_medium'
                            _dark={{
                              color: "warmGray.50",
                            }}
                            color='#000000'
                          >
                            {customer.name}
                          </Text>

                          <Text
                            opacity={0.5}
                            color={"#000000"}
                            fontSize={13}
                            fontFamily='Poppins_medium'
                          >
                            {started}

                            <Entypo
                              ref={(el) => (sRef.current[cid] = el)}
                              style={{ position: "absolute" }}
                              size={60}
                              name='dot-single'
                              color={"darkgreen"}
                              onPress={() => {}}
                            />
                          </Text>
                        </VStack>
                      </Center>
                      <Spacer />
                      <Center>
                        <HStack space={2}>
                          <Pressable
                            mt={1}
                            onPress={() => {
                              setPm((c) => {
                                if (c[p].minimized) {
                                  c[p].minimized = false;
                                } else {
                                  c[p].minimized = true;
                                }
                                return c;
                              });
                              setRefresh(moment().valueOf());
                            }}
                          >
                            <Minimize />
                          </Pressable>
                          <Pressable
                            mt={1}
                            mr={-2}
                            onPress={() => {
                              if (!showagentnote) {
                                setPm((c) => {
                                  if (c[p].showagentnote) {
                                    c[p].showagentnote = false;
                                  } else {
                                    c[p].showagentnote = true;
                                  }
                                  return c;
                                });
                                setRefresh(moment().valueOf());
                                return;
                              }

                              const notes = iRef.current[idx].value;
                              setPm((c) => {
                                delete c[p];
                                return c;
                              });
                              socket.emit("pm:close", {
                                room: cb.room,
                                notes,
                                left: latestMsg.system ? true : false,
                              });
                              setRefresh(moment().valueOf());
                            }}
                          >
                            <Close />
                          </Pressable>
                        </HStack>
                      </Center>
                    </HStack>
                    <HStack space={1} px={1}>
                      <Pressable
                        onPress={() => {
                          setShowPreviousChats(true);
                          setShowModal(true);
                          setPcCustomer(customer.id);
                        }}
                        borderRadius={50}
                        bg={"coolGray.300"}
                        style={{
                          paddingHorizontal: 5,
                        }}
                      >
                        <Text fontSize={12}>Previous Chats</Text>
                      </Pressable>
                      {templates && templates.length > 0 && (
                        <Menu
                          w='190'
                          ml={2}
                          _text={{ fontSize: 12 }}
                          trigger={(triggerProps) => {
                            return (
                              <Pressable
                                accessibilityLabel='More options menu'
                                {...triggerProps}
                                borderRadius={50}
                                bg={"coolGray.300"}
                                style={{
                                  paddingHorizontal: 5,
                                }}
                              >
                                <Text fontSize={12}>Templates</Text>
                              </Pressable>
                            );
                          }}
                        >
                          {templates &&
                            templates.length &&
                            templates.map((m, i) => (
                              <Menu.Item
                                key={"m-" + i}
                                onPress={() => {
                                  socket.emit("pm", {
                                    _id: uuidv4(),
                                    text: m.name,
                                    createdAt: moment().toISOString(),
                                    user: {
                                      _id: user._id,
                                      name:
                                        user.firstname + " " + user.lastname,
                                      email: user.email,
                                      agent: true,
                                      room: p,
                                    },
                                  });
                                }}
                              >
                                {m.name}
                              </Menu.Item>
                            ))}
                        </Menu>
                      )}
                    </HStack>
                  </Pressable>
                  {!cb.minimized && (
                    <Box flex={1} p={1}>
                      <FlatList
                        // ListFooterComponent={
                        //   cb.messages.length === 0 ? null : (
                        //     <Center marginTop={-30}>
                        //       <Pressable
                        //         onPress={() => {
                        //           support.emit("get:messages", {
                        //             filter: { customer: profile._id },
                        //             skip: messages.length,
                        //             limit: LIMIT,
                        //           });
                        //           setIsLoadingEarlier(true);
                        //         }}
                        //         style={{
                        //           flex: 1,
                        //           borderRadius: 50,
                        //           width: 180,
                        //           justifyContent: "center",
                        //           paddingHorizontal: 10,
                        //           paddingVertical: 5,
                        //           backgroundColor: "rgba(125, 43, 233, 0.2)",
                        //         }}
                        //       >
                        //         <Text
                        //           style={{
                        //             textAlign: "center",
                        //             fontFamily: "Poppins_medium",
                        //             fontSize: 13,
                        //             color: "rgba(51, 33, 75, 1)",
                        //           }}
                        //         >
                        //           Load earlier messages
                        //         </Text>
                        //       </Pressable>
                        //     </Center>
                        //   )
                        // }
                        inverted
                        ref={fRef} // assign the flatlist's ref to your component's FlatListRef...
                        // onContentSizeChange={() =>
                        // fRef.current.scrollToOffset({ animated: true, offset: 0 })
                        // } // scroll it
                        contentContainerStyle={{}}
                        // getItemLayout={_getItemLayout}
                        style={{ flex: 1, marginBottom: 50 }}
                        data={cb.messages}
                        keyExtractor={_keyExtractor}
                        renderItem={({ item, index }) => {
                          // console.log("item", item.user, user._id);
                          const chatuser =
                            item.user && item.user._id === user._id;
                          return (
                            <VStack>
                              {/* <Center py={3}>
                                <Text
                                  style={{
                                    fontSize: 13,
                                    fontFamily: "Poppins",
                                    color: "rgba(0,0,0,0.5)",
                                  }}
                                >
                                  {item.date === moment().format("MMM DD, YYYY")
                                    ? "Today"
                                    : item.date}
                                </Text>
                              </Center> */}

                              <HStack
                                key={item._id}
                                style={{
                                  justifyContent: chatuser
                                    ? "flex-end"
                                    : "flex-start",
                                }}
                              >
                                <VStack
                                  style={{
                                    marginHorizontal: 15,
                                    marginVertical: 5,
                                    padding: 5,
                                    borderRadius: 8,
                                    borderTopLeftRadius: !chatuser ? 0 : 8,
                                    borderTopRightRadius: !chatuser ? 8 : 0,
                                    backgroundColor: !chatuser
                                      ? "rgba(0,0,0, 0.05)"
                                      : "rgba(125, 43, 233, 0.8)",

                                    // minWidth: vw(100),
                                    maxWidth: 400,
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: chatuser
                                        ? "white"
                                        : "rgba(0,0,0,0.8)",
                                      // fontFamily: "Roboto",
                                      fontSize: 13,
                                    }}
                                  >
                                    {item.text}
                                  </Text>
                                  <Text
                                    style={{
                                      // fontFamily: "Roboto",
                                      fontSize: 11,
                                      color: chatuser
                                        ? "white"
                                        : "rgba(0,0,0,0.8)",
                                      textAlign: "right",
                                      marginTop: -2,
                                    }}
                                  >
                                    {moment(item.createdAt).format("HH:mm a")}
                                  </Text>
                                </VStack>
                              </HStack>
                            </VStack>
                          );
                        }}
                      />
                      <HStack
                        flex={1}
                        style={{
                          position: "absolute",
                          bottom: 2,
                          height: 50,
                          justifyContent: "space-between",
                          width: "100%",
                          borderTopColor: "rgba(0,0,0,0.15)",
                          borderTopWidth: 1.5,
                        }}
                      >
                        <Input
                          type='text'
                          autoComplete='Off'
                          autoFocus
                          ref={(el) => (cRef.current[customer.email] = el)}
                          variant={"unstyled"}
                          width='full'
                          placeholder='Type something'
                          placeholderTextColor={"rgba(145, 145, 145, 1)"}
                          style={{
                            height: 40,
                            // fontFamily: "Roboto",
                            fontSize: 13,
                            color: "rgba(0,0,0,0.8)",
                          }}
                          onSubmitEditing={(v) => {
                            // console.log("v", v.target.value);
                            send(v.target.value, customer);
                          }}
                          onChange={(e) => {
                            // console.log("e", e);
                            setMsg(e.target.value);
                          }}
                          InputRightElement={
                            <Pressable
                              onPress={() => {
                                send(cRef.current[customer.email].value, customer);
                              }}
                              style={{ marginHorizontal: 15 }}
                            >
                              <Text
                                style={{
                                  color:
                                    msg === ""
                                      ? "rgba(145, 145, 145, 1)"
                                      : "#303030",
                                  // fontFamily: "Roboto",
                                  fontSize: 15,
                                }}
                              >
                                Send
                              </Text>
                            </Pressable>
                          }
                        />
                      </HStack>

                      {/* <GiftedChat
                        style={{ margin: 5 }}
                        multiline={false}
                        renderUsernameOnMessage={false}
                        showAvatarForEveryMessage
                        messagesContainerStyle={{ backgroundColor: "white" }}
                        infiniteScroll={true}
                        loadEarlier={true}
                        isLoadingEarlier={isLoadingEarlier}
                        // listViewProps={{
                        //   scrollEventThrottle: 100,
                        //   onScroll: ({ nativeEvent }) => {
                        //     if (isCloseToTop(nativeEvent)) {
                        //       const p = page + 1;
                        //       socket.emit("csp:messages", { csp: user._id, page: p });
                        //       setPage(p);
                        //       setIsLoadingEarlier(true);
                        //     }
                        //   },
                        // }}
                        onLoadEarlier={() => {
                          console.log(customer, "CUSTOMER");
                          socket.emit("get:messages", {
                            filter: {
                              room: customer.email,
                              agent: user._id,
                              customer: customer.id,
                            },
                            skip: cb.messages.length,
                            limit: 10,
                          });
                          setIsLoadingEarlier(true);
                        }}
                        renderBubble={(props) => (
                          <Bubble
                            {...props}
                            textStyle={{
                              right: {
                                color: "#4B4B4B",
                                fontSize: 13,
                                fontFamily: "Poppins_medium",
                              },
                              left: {
                                color: "#4B4B4B",
                                fontSize: 13,
                                fontFamily: "Poppins_medium",
                              },
                            }}
                            wrapperStyle={{
                              left: {
                                backgroundColor: "#F7F7F7",
                              },
                              right: {
                                backgroundColor: "#EAE3F8",
                              },
                            }}
                          />
                        )}
                        messages={cb.messages}
                        onSend={(m) => {
                          // console.log("ONSEND", m[0])
                          socket.emit("pm", m[0]);
                        }}
                        user={{
                          _id: user._id,
                          name: user.firstname + " " + user.lastname,
                          email: user.email,
                          agent: true,
                          room: p,
                          avatar: null,
                        }}
                        textInputStyle={{
                          marginBottom: 10,
                          paddingLeft: 5,
                          fontSize: 13,
                          color: "#303030",
                          fontFamily: "Poppins_medium",
                        }}
                        timeTextStyle={{
                          left: { color: "black" },
                          right: { color: "black" },
                        }}
                        renderComposer={ChatComposer}
                        renderAvatar={() => null}
                        renderSend={(props) => {
                          return (
                            <Pressable
                              // my={1.5}
                              p={2}
                              onPress={() => {
                                if (props.text && props.onSend) {
                                  props.onSend(
                                    { text: props.text.trim() },
                                    true
                                  );
                                }
                              }}
                            >
                               <FontAwesome
                                color={Colors.primary}
                                name='send'
                                size={16}
                              /> 
                              <Send />
                            </Pressable>
                          );
                        }}
                      /> */}
                    </Box>
                  )}
                </VStack>
              );
            })}
        </HStack>
      </HStack>
    </Box>
  );
}

export default Support;
