import React, { useState } from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  HStack,
  useToast,
  Center,
  Pressable,
  useContrastText,
  useColorModeValue,
} from "native-base";
import { set, useForm } from "react-hook-form";
// import { FontAwesome5 } from "@expo/vector-icons";
import { useAsyncStorage } from "@react-native-async-storage/async-storage";
import { AlertHelper } from "../../AlertHelper";

const Login = ({ setIsIn, setIsSignup }) => {
  const { setItem } = useAsyncStorage("@supportUser");
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [saving, setSaving] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const screenbgColor = useColorModeValue("warmGray.50", "coolGray.800");
  const inputColor = useContrastText(screenbgColor);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (logindata) => {
    try {
      setSaving(true);

      var body = JSON.stringify({
        ...logindata,
      });

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        redirect: "follow",
      };
      let url = "https://chat.shadow.properties/login";
      // url = "http://localhost:9001/login";
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const { data, error } = await response.json();
        if (data) {
          setIsIn(true);
          setItem(JSON.stringify(data));
        } else {
          AlertHelper.show("error", "Error", error);
        }
      } else {
        AlertHelper.show("error", "Error", "an error occurred, signin failed");
      }
      setSaving(false);
    } catch (err) {
      AlertHelper.show("error", "Error", "an error occurred, signin failed");
      console.log(err.message);
      setSaving(false);
    }
  };

  return (
    <Center mt={-20} flex={1} bg={screenbgColor}>
      <Box mx={10} p='2' py='4' w='90%' maxW='290'>
        <Heading
          size='lg'
          fontWeight='600'
          color='coolGray.800'
          _dark={{
            color: "warmGray.50",
          }}
        >
          Welcome
        </Heading>
        <Heading
          mt='1'
          _dark={{
            color: "warmGray.200",
          }}
          color='coolGray.600'
          fontWeight='medium'
          size='xs'
        >
          Sign in to continue!
        </Heading>

        <VStack space={3} mt='5'>
          <FormControl
            isRequired
            isInvalid={errors && errors.email ? true : false}
          >
            <FormControl.Label>Email ID </FormControl.Label>
            <Input
              color={inputColor}
              bg={screenbgColor}
              // color="warmGray.800"
              // _dark={{
              //   color:  useContrastText("white"),
              // }}
              autoComplete='new-email'
              {...register("email", {
                required: "email is required",
                maxLength: 200,
              })}
              value={email}
              onChangeText={(v) => {
                setEmail(v);
                setValue("email", v);
              }}
            />
            <FormControl.ErrorMessage _text={{ fontSize: "xs" }}>
              {errors.email && errors.email.message}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors && errors.password ? true : false}
          >
            <FormControl.Label>Password </FormControl.Label>
            <Input
              bg={screenbgColor}
              color={inputColor}
              // _dark={{
              //   color: "warmGray.50",
              // }}
              autoComplete='new-password'
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "password is required",
                maxLength: 20,
              })}
              value={password}
              onChangeText={(v) => {
                setPassword(v);
                setValue("password", v);
              }}
              // InputRightElement={
              // <Pressable
              //   onPress={() => {
              //     setShowPassword(!showPassword);
              //   }}
              //   // bg={screenbgColor}
              //   color={inputColor}
              // >
              //   {showPassword ? (
              //     <FontAwesome5
              //       name="eye"
              //       color={inputColor}
              //       style={{
              //         marginLeft: 5,
              //         marginRight: 5,
              //         color: inputColor,
              //         // backgroundColor: screenbgColor,
              //       }}
              //     />
              //   ) : (
              //     <FontAwesome5
              //       name="eye-slash"
              //       color={inputColor}
              //       style={{
              //         marginLeft: 5,
              //         marginRight: 5,
              //         color: inputColor,
              //         // backgroundColor: screenbgColor,
              //       }}
              //     />
              //   )}
              // </Pressable>
              // }
            />
            <FormControl.ErrorMessage _text={{ fontSize: "xs" }}>
              {errors.password && errors.password.message}
            </FormControl.ErrorMessage>
            <Button
              size='sm'
              p={0}
              variant='unstyled'
              onPress={() => {
                setScreen("forgot");
              }}
              _text={{
                fontSize: "xs",
                fontWeight: "500",
                color: "indigo.500",
              }}
              alignSelf='flex-end'
              mt='1'
            >
              Forget Password?
            </Button>
          </FormControl>
          <Button
            isLoading={saving}
            onPress={() => {
              handleSubmit(onSubmit)();
            }}
            mt='2'
            colorScheme='indigo'
          >
            Sign in
          </Button>
          {/* <HStack space={5} mt='6' justifyContent='center'>
            <Text
              fontSize='sm'
              color='coolGray.600'
              _dark={{
                color: "warmGray.200",
              }}
            >
              I'm a new user.{" "}
            </Text>
            <Button
              size='sm'
              p={0}
              variant='unstyled'
              onPress={() => {
                setScreen("register");
              }}
              _text={{
                color: "indigo.500",
                fontWeight: "medium",
                fontSize: "sm",
              }}
            >
              Sign Up
            </Button>
          </HStack> */}
        </VStack>
      </Box>
    </Center>
  );
};

export default Login;
